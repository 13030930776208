.legal {
    padding-bottom: 100px;
    .title {
        text-align: center;
        font-size: 1.5rem;
        margin-top: 30px;
    }
    .subtitle {
        color: rgb(0, 79, 139);
        margin-top: 20px;
        margin-bottom: 10px;
    }
    p {
        margin-top: 5px;
            margin-bottom: 5px;
        &.margin {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    a {
        color: rgb(0, 79, 139);
    }
    ul {
        list-style-type: disc;
        margin-left: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        li {
            font-size: 0.9rem;
        }
    }
    .bold {
        font-weight: bold;
    }
    .large {
        font-size: 1.1rem;
    }
}